import type { App } from "vue";
import VueGtag, { setOptions, bootstrap } from "vue-gtag";
import type { ICredentials } from "~/types";
import { apiService } from "../api";
import { router } from "../router";

export default async (app: App) => {
  app.use(
    VueGtag,
    {
      bootstrap: false,
      pageTrackerScreenviewEnabled: true,
    },
    router
  );

  let credentials: ICredentials = {};
  const response = await apiService.get<ICredentials[], unknown>(
    "api/v2/app-config"
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    credentials = response.data[0];
  }

  if (credentials.ga_app_id?.length && credentials.ga_app_name?.length) {
    setOptions({
      appName: credentials.ga_app_name,
      pageTrackerScreenviewEnabled: true,
      config: { id: credentials.ga_app_id },
    });
    bootstrap();
  }

  if (credentials.pagesense_path?.length) {
    // const PAGESENSE_URL =
    //   "https://cdn.pagesense.io/js/draivncorp/9c60e2082ff74176ac79a7860085de86.js";
    const pagesenseScript = document.createElement("script");
    pagesenseScript.src = `https://cdn.pagesense.io/js/${credentials.pagesense_path}.js`;
    pagesenseScript.type = "text/javascript";
    document.head.appendChild(pagesenseScript);
  }
};
