import { EAuthTypes, EPlatformName } from "../types";
// import GeotabBasic from "../components/blocks/telematics/connectors/GeotabBasic.vue";
import BaseToken from "../components/blocks/telematics/connectors/BaseToken.vue";
import BaseOauth from "../components/blocks/telematics/connectors/BaseOauth.vue";
import BaseBasic from "../components/blocks/telematics/connectors/BaseBasic.vue";
import TrimbleBasic from "../components/blocks/telematics/connectors/TrimbleBasic.vue";
import WebfleetBasic from "../components/blocks/telematics/connectors/WebfleetBasic.vue";
import BaseAccountName from "../components/blocks/telematics/connectors/BaseAccountName.vue";
import BaseAccountNameWOInstructionsConfirmation from "../components/blocks/telematics/connectors/BaseAccountNameWOInstructionsConfirmation.vue";
import BaseUsernameToken from "../components/blocks/telematics/connectors/BaseUsernameToken.vue";
import BaseUserPassword from "../components/blocks/telematics/connectors/BaseUserPassword.vue";
import BaseEmail from "../components/blocks/telematics/connectors/BaseEmail.vue";
import VerizonAccountName from "../components/blocks/telematics/connectors/VerizonAccountName.vue";
import WaylensBasic from "../components/blocks/telematics/connectors/WaylensBasic.vue";
import { Component } from "vue";
import RequestTsp from "../components/blocks/telematics/connectors/RequestTsp.vue";
import BaseDatabase from "@/modules/onboarding/components/blocks/telematics/connectors/BaseDatabase.vue";
import NetradyneBasic from "@/modules/onboarding/components/blocks/telematics/connectors/NetradyneBasic.vue";
import BaseDotNumber from "../components/blocks/telematics/connectors/BaseDotNumber.vue";
import GorillaSafetyBasic from "../components/blocks/telematics/connectors/GorillaSafetyBasic.vue";

export const platformMethodForm: Partial<
  Record<`${EPlatformName}-${EAuthTypes}`, Component>
> = {
  // [`${EPlatformName.GEOTAB}-${EAuthTypes.BASIC}`]: GeotabBasic, // deprecated in favor of DatabaseBasic 26.04.2024
  [`${EPlatformName.GEOTAB}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ACTSOFT_ENCORE}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ADVANTAGE_ASSET_TRACKING}-${EAuthTypes.BASIC}`]:
    BaseDatabase,
  [`${EPlatformName.ARGOS_CONNECTED_SOLUTIONS}-${EAuthTypes.BASIC}`]:
    BaseDatabase,
  [`${EPlatformName.ARI_FLEET}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ASSURED_TELEMATICS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ATNT_FLEET}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ATTRIX}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.BADGER_FLEET_SOLUTIONS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.BAR_CODING_CANADA}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.BLUEARROW}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.CARRIERHQ}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ENVUE_TELEMATICS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.FLEET_NAV_SYSTEMS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.FLEET_PROFIT_CENTER}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.FLEETBOSS_GPS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.FLEETHOSTER}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.FLEETISTICS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.FLEETLOCATE_GEOTAB}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.FLEETMASTER}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.FLEXPORT}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GEOFORCE}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GLOSTONE}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GOFLEET}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GOGPS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GPS_FLEET_FINDER}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GPS_SOLUTIONS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GPS_TRACKING_CANADA}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GRAYBOX_SOLUTIONS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.GRIDLINE}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.HIGHPOINT_GPS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.IOTAB}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.LYNX}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ONTRAK_SOLUTIONS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.PENSKE}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.PREPASS_ELD}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.QUALITY_GPS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.RMJ_TECHNOLOGIES}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.RUSH_ENTERPRISES}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.RYDER}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.SAFETY_VISION}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.SHELL_TELEMATICS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.SURECAM}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.T_MOBILE}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.TRANSFLO}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.TRAXXISGPS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.TRUPATH_SYSTEMS}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.VERTRAX}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ZENDUIT}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.HOLMAN}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.ENTERPRISE_FLEET_MANAGEMENT}-${EAuthTypes.BASIC}`]:
    BaseDatabase,
  [`${EPlatformName.DICAN}-${EAuthTypes.BASIC}`]: BaseDatabase,
  [`${EPlatformName.SAMSARA}-${EAuthTypes.OAUTH2}`]: BaseOauth,
  [`${EPlatformName.SAMSARA}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.SAMSARA}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.MOTIVE}-${EAuthTypes.OAUTH2}`]: BaseOauth,
  [`${EPlatformName.MOTIVE}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.MOTIVE}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.TRIMBLE}-${EAuthTypes.BASIC}`]: TrimbleBasic,
  [`${EPlatformName.WAYLENS}-${EAuthTypes.BASIC}`]: WaylensBasic,
  [`${EPlatformName.WEBFLEET}-${EAuthTypes.ACCOUNT}`]: BaseAccountName,
  [`${EPlatformName.WEBFLEET}-${EAuthTypes.BASIC}`]: WebfleetBasic,
  [`${EPlatformName.VERIZON}-${EAuthTypes.ACCOUNT}`]: VerizonAccountName,
  [`${EPlatformName.VERIZON}-${EAuthTypes.BASIC}`]: BaseBasic,
  // TODO: for 2024 integration C0
  // [`${EPlatformName.GPS_INSIGHT}-${EAuthTypes.ACCOUNT}`]: BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.GPS_INSIGHT}-${EAuthTypes.TOKEN}`]: BaseUsernameToken,
  [`${EPlatformName.INSIGHT_MOBILE_DATA}-${EAuthTypes.TOKEN}`]:
    BaseUsernameToken,
  [`${EPlatformName.MONARCH_GPS}-${EAuthTypes.TOKEN}`]: BaseUsernameToken,
  [`${EPlatformName.RHINO}-${EAuthTypes.TOKEN}`]: BaseUsernameToken,
  [`${EPlatformName.TOUCHTEL_GPS}-${EAuthTypes.TOKEN}`]: BaseUsernameToken,
  [`${EPlatformName.TRUCKSPY}-${EAuthTypes.TOKEN}`]: BaseUsernameToken,
  [`${EPlatformName.WIALON}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.WIALON}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.GPS_COMMANDER}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.GPS_COMMANDER}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.MIX_TELEMATICS}-${EAuthTypes.BASIC}`]: BaseUserPassword,
  [`${EPlatformName.EROAD}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.EROAD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.FLEET_COMPLETE}-${EAuthTypes.ACCOUNT}`]: BaseAccountName,
  [`${EPlatformName.FLEET_COMPLETE}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ATNT_FLEET_COMPLETE}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountName,
  [`${EPlatformName.ATNT_FLEET_COMPLETE}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.FLEET_UP}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.FLEET_UP}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.GPS_TAB}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.GPS_TAB}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.LION_EIGHT}-${EAuthTypes.BASIC}`]: BaseBasic,
  // [`${EPlatformName.LYTX}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.LYTX}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.LYTX}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.OMNITRACS}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.OMNITRACS}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ISAAC}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.AZUGA}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.AZUGA_ELD}-${EAuthTypes.BASIC}`]: BaseUserPassword,
  [`${EPlatformName.WORKWAVE}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.NETRADYNE}-${EAuthTypes.BASIC}`]: NetradyneBasic,
  [`${EPlatformName.NEXTRAQ}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ONE_STEP_GPS}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ONE_STEP_GPS}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.FLEETLOCATE_SPIREON_NSPIRE}-${EAuthTypes.BASIC}`]:
    BaseEmail,
  [`${EPlatformName.XRS}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.XRS}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ZONAR}-${EAuthTypes.BASIC}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.ORBCOMM_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ORBCOMM_BLUE_TREE}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.VISION_TRACK}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.GORILLA_SAFETY}-${EAuthTypes.ACCOUNT}`]: GorillaSafetyBasic,
  [`${EPlatformName.GORILLA_SAFETY}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.CLUTCH_ELD}-${EAuthTypes.ACCOUNT}`]: GorillaSafetyBasic,
  [`${EPlatformName.CLUTCH_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.CYNTRX_ELD_PLUS}-${EAuthTypes.ACCOUNT}`]:
    GorillaSafetyBasic,
  [`${EPlatformName.CYNTRX_ELD_PLUS}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ELD_MANDATE_PRO}-${EAuthTypes.ACCOUNT}`]:
    GorillaSafetyBasic,
  [`${EPlatformName.ELD_MANDATE_PRO}-${EAuthTypes.BASIC}`]: BaseBasic,
  // [`${EPlatformName.FLEET_LOCATE_ELD}-${EAuthTypes.TOKEN}`]: BaseDotNumber,
  // [`${EPlatformName.FLEET_LOCATE_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.PATRIOT}-${EAuthTypes.ACCOUNT}`]: GorillaSafetyBasic,
  [`${EPlatformName.PATRIOT}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.SIMPLEX_ELD}-${EAuthTypes.ACCOUNT}`]: GorillaSafetyBasic,
  [`${EPlatformName.SIMPLEX_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.TRACK_ON_ELD}-${EAuthTypes.ACCOUNT}`]: GorillaSafetyBasic,
  [`${EPlatformName.TRACK_ON_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.TRUCKER_PATH_ELD_PRO}-${EAuthTypes.ACCOUNT}`]:
    GorillaSafetyBasic,
  [`${EPlatformName.TRUCKER_PATH_ELD_PRO}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.LINXUP}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.LINXUP}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.LION_EIGHT}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.LION_EIGHT}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ELD_RIDER_PRO}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.ELD_RIDER_PRO}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.SHELD}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.SHELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.XELD}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.XELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.ZELD}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.ZELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.EVA_ELD}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.EVA_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.XPLORE_ELD}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.XPLORE_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.OPTIMA_ELD}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.OPTIMA_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.PRO_RIDE_ELD}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.PRO_RIDE_ELD}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.FLEETSHARP}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.FLEETSHARP}-${EAuthTypes.BASIC}`]: BaseBasic,
  [`${EPlatformName.REQUEST}-${EAuthTypes.REQUEST}`]: RequestTsp,
  [`${EPlatformName.TELETRAC_NAVMAN}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.INTELLISHIFT}-${EAuthTypes.BASIC}`]: BaseUserPassword,
  [`${EPlatformName.EZ_FLEET}-${EAuthTypes.TOKEN}`]: BaseToken,
  [`${EPlatformName.SIMPLE_TRUCK_ELD}-${EAuthTypes.ACCOUNT}`]: BaseDotNumber,
  [`${EPlatformName.SIMPLE_E_LOG}-${EAuthTypes.ACCOUNT}`]: BaseDotNumber,
  [`${EPlatformName.GLOBAL_ELD}-${EAuthTypes.ACCOUNT}`]: BaseDotNumber,
  [`${EPlatformName.MICHELIN_CONNECTED_FLEET}-${EAuthTypes.ACCOUNT}`]:
    BaseAccountNameWOInstructionsConfirmation,
  [`${EPlatformName.MICHELIN_CONNECTED_FLEET}-${EAuthTypes.BASIC}`]: BaseBasic,
};
